import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useColors } from "../shared/colors/Colors";

interface NavButtonProps {
  text: string;
  to: string;
}

export const NavButton: React.FC<NavButtonProps> = ({ text, to }) => {
  const colors = useColors();

  const styles = {
    button: {
      color: "white",
      border: `1px solid ${colors.black}`,
      "&:hover": {
        border: "1px solid #E0F7FA",
      },
      textTransform: "none",
    },
  };

  return (
    <Button component={Link} to={to} sx={styles.button}>
      {text}
    </Button>
  );
};
