// src/components/NavBar.js
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { Link } from "react-router-dom";
import { NavButton } from "../atoms/NavButton";
import { useColors } from "../shared/colors/Colors";
import logo from "../shared/pictures/data-spark-logo.png";
import zIndex from "@mui/material/styles/zIndex";

const NavBar = () => {
  const colors = useColors();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget as HTMLElement);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  // Extracted styles
  const styles = {
    appBar: {
      bgcolor: colors.black,
      border: "1px solid #E0F7FA",
      borderRadius: "20px",
      "&:hover": {
        boxShadow: "0 0 20px rgba(173, 216, 230, 0.9)",
      },
    },
    logo: {
      height: "60px",
      padding: "20px",
    },
    button: {
      bgcolor: "white",
      color: "black",
      borderRadius: "5px",
      "&:hover": {
        boxShadow: "0 0 20px rgba(173, 216, 230, 0.2)",
        bgcolor: "white",
      },
      textTransform: "none",
    },
  };

  const navOptions = [
    { text: "Overview", to: "/overview" },
    { text: "Services", to: "/services" },
    { text: "Team", to: "/team" },
    // Add more options as needed
  ];

  return (
    <Box sx={{ m: 2 }}>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <img src={logo} alt="logo" style={styles.logo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {isMobile ? (
                <div>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={Boolean(mobileMoreAnchorEl)}
                    onClose={handleMobileMenuClose}
                  >
                    <MenuItem
                      component={Link}
                      to="/overview"
                      onClick={handleMobileMenuClose}
                      sx={{ textTransform: "none" }}
                    >
                      Overview
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/team"
                      onClick={handleMobileMenuClose}
                      sx={{ textTransform: "none" }}
                    >
                      Team
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/services"
                      onClick={handleMobileMenuClose}
                      sx={{ textTransform: "none" }}
                    >
                      Services
                    </MenuItem>
                    <MenuItem
                      onClick={handleMobileMenuClose}
                      sx={{ textTransform: "none" }}
                    >
                      <Button variant="contained" sx={styles.button}>
                        Start a project
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <Grid container alignItems="center" spacing={2}>
                  {navOptions.map((option, index) => (
                    <Grid item key={index}>
                      <NavButton
                        key={index}
                        text={option.text}
                        to={option.to}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <Button
                      component={Link}
                      to="/contact"
                      variant="contained"
                      sx={styles.button}
                    >
                      Get In Touch
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
