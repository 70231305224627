import { Grid } from "@mui/material";
import React from "react";
import "./App.css";
import { useColors } from "./components/shared/colors/Colors";
import { MainLayout } from "./components/templates/MainLayout";

const App: React.FC = () => {
  const colors = useColors();

  return (
    <div>
      <Grid
        sx={{
          backgroundColor: colors.appBackGroundColor,
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "20px",
          paddingRight: "10px",
          minHeight: "100vh",
        }}
      >
        <MainLayout />
      </Grid>
    </div>
  );
};

export default App;
