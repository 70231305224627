import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useColors } from "../shared/colors/Colors";
import { WebDevelopment } from "../atoms/WebDevelopment";
import { MobileDevelopment } from "../atoms/MobileDevelopment";
import { HeroIcon } from "../atoms/HeroIcon";

function heroSection(colors: any) {
  const LeftContent = (
    <Grid
      sx={{ flexGrow: 1 }}
      item
      container
      md={6}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={6} sx={{ zIndex: 10 }}>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 55,
              lineHeight: 1.0,
              fontWeight: 700,
              color: colors.grey,
            }}
          >
            Ignite Innovation, Transforming Ideas into Reality
          </Typography>
          <Stack direction="row" alignItems="center" spacing={6}>
            <Typography
              sx={{ fontSize: 20, color: colors.grey, maxWidth: 550 }}
            >
              Let's spark your next big breakthrough and build the future of
              tech, together.
            </Typography>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    </Grid>
  );

  const RightContent = (
    <Grid
      sx={{ flexGrow: 1, position: "relative" }}
      item
      container
      md={6}
      xs={12}
      justifyContent="center"
      alignItems="center"
      className="hero"
      // paddingTop="0"
    >
      <HeroIcon />
    </Grid>
  );

  return (
    <Grid
      container
      spacing={{ xs: 15, md: 2 }}
      sx={{ flexGrow: 1, bgcolor: colors.darkBlue }}
      minHeight="100vh"
      // marginTop="-50px"
    >
      {LeftContent}
      {RightContent}
    </Grid>
  );
}

function WhoWeAreSection(colors: any) {
  const MiddleContent = (
    <Grid
      sx={{ flexGrow: 1 }}
      item
      container
      // md={9}
      // xs={12}
      justifyContent="center"
      alignItems="center"
      textAlign={"center"}
    >
      <Stack spacing={6} sx={{ zIndex: 10 }}>
        <Stack spacing={3}>
          <Typography
            variant="h3"
            sx={{
              fontSize: 30,
              lineHeight: 1.0,
              fontWeight: 700,
              color: colors.navy,
            }}
          >
            Who we are
          </Typography>
          <Stack direction="column" alignItems="center" spacing={6}>
            <Typography
              variant="h2"
              sx={{
                fontSize: 40,
                color: colors.grey,
                fontWeight: 700,
                maxWidth: 750,
              }}
            >
              We empower businesses to scale, innovate, and thrive in an
              ever-evolving digital world.
            </Typography>
            <Stack direction="column" alignItems="center" spacing={6}>
              <Typography
                sx={{ fontSize: 20, color: colors.grey, maxWidth: 750 }}
              >
                At Dataspark Innovations, we’re more than just developers—we’re
                innovators, creators, and problem-solvers. We design and build
                cutting-edge software solutions that turn visionary ideas into
                powerful technologies. Whether you’re a startup or an
                established enterprise, our expertise in tech and development
                empowers your business to scale, adapt, and lead in a rapidly
                changing digital landscape.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={{ xs: 15, md: 2 }}
      sx={{ flexGrow: 1, bgcolor: colors.denimBlue }}
      minHeight="100vh"
    >
      {MiddleContent}
    </Grid>
  );
}

function ServiceSection(colors: any) {
  const TopContent = (
    <Grid
      sx={{ flexGrow: 1 }}
      item
      container
      justifyContent="center"
      alignItems="center"
      textAlign={"center"}
    >
      <Stack spacing={6} sx={{ zIndex: 10 }}>
        <Stack spacing={3}>
          <Typography
            variant="h3"
            sx={{
              fontSize: 30,
              lineHeight: 1.0,
              fontWeight: 700,
              color: colors.navy,
            }}
          >
            Our Services
          </Typography>
          <Stack direction="column" alignItems="center" spacing={6}>
            <Typography
              variant="h2"
              sx={{ fontSize: 40, color: colors.grey, fontWeight: 700 }}
            >
              What we specialize in
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );

  const LeftContent = (
    <Grid
      sx={{ flexGrow: 1 }}
      item
      container
      md={6}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        spacing={6}
        sx={{ zIndex: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={6}
          justifyContent="center"
        >
          <WebDevelopment />
        </Stack>
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: 30,
              lineHeight: 1.0,
              fontWeight: 700,
              color: colors.grey,
            }}
          >
            WEB DEVELOPMENT
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={6}
            justifyContent="center"
          >
            <Typography
              sx={{ fontSize: 20, color: colors.grey, maxWidth: 500 }}
            >
              From dynamic websites to complex web applications, we specialize
              in creating scalable, responsive, and visually stunning web
              solutions.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );

  const RightContent = (
    <Grid
      sx={{ flexGrow: 1 }}
      item
      container
      md={6}
      xs={12}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        spacing={6}
        sx={{ zIndex: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" alignItems="center" spacing={6}>
          <MobileDevelopment />
        </Stack>
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: 30,
              lineHeight: 1.0,
              fontWeight: 700,
              color: colors.grey,
            }}
          >
            MOBILE DEVELOPMENT
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={6}
            justifyContent="center"
          >
            <Typography
              sx={{ fontSize: 20, color: colors.grey, maxWidth: 500 }}
            >
              We craft intuitive, high-performance mobile apps that engage users
              and drive business growth. Whether it’s iOS, Android, or
              cross-platform development.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={{ xs: 15, md: 2 }}
      sx={{ flexGrow: 1, bgcolor: colors.denimBlue }}
      minHeight="100vh"
      textAlign="center"
    >
      {TopContent}
      {LeftContent}
      {RightContent}
    </Grid>
  );
}

export const Overview = () => {
  var colors = useColors();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "120px"
      }}
    >
      {heroSection(colors)}
      {WhoWeAreSection(colors)}
      {ServiceSection(colors)}
    </Box>
  );
};
