import emailjs from "@emailjs/browser";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useColors } from "../shared/colors/Colors";
import BlueLogo from "../shared/pictures/data-spark-logo-blue.png";

const customStyles = {
  container: (isMobile: boolean, colors: any) => ({
    backgroundColor: colors.background,
    padding: isMobile ? 2 : 4,
    borderRadius: "20px",
    color: "white",
    marginTop: 4,
    textAlign: "center",
    width: isMobile ? "100%" : "70%",
    marginX: "auto",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
  }),
  toggleButton: (colors: any) => ({
    color: "white",
    borderRadius: "10px",
    border: "1px solid #333",
    padding: "8px 16px",
    minWidth: "120px",
    height: "50px",
    "&.Mui-selected": {
      backgroundColor: colors.toggleSelected,
      color: "white",
      boxShadow: "0 0 5px rgba(48, 56, 65, 0.6)",
      border: "none",
    },
    "&:hover": {
      backgroundColor: colors.toggleHover,
      boxShadow: "0 0 5px rgba(42, 47, 51, 0.4)",
    },
  }),
  textField: (colors: any) => ({
    input: { color: "white" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: colors.borderColor },
      "&:hover fieldset": { borderColor: colors.hoverBorderColor },
    },
  }),
  button: {
    bgcolor: "white",
    color: "black",
    borderRadius: "5px",
    "&:hover": {
      boxShadow: "0 0 20px rgb(173, 216, 230, 0.2)",
      bgcolor: "white",
    },
    textTransform: "none",
  },
  icon: (colors: any) => ({
    color: colors.buttonBackground,
    marginRight: 1,
  }),
  alert: { mb: 2 },
  logo: {
    maxWidth: "150px",
    marginTop: 2,
  },
};

type Inputs = {
  name: string;
  email: string;
  message: string;
};

export const Contact: React.FC = () => {
  const colors = useColors(); // Use the useColors hook

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [selectedService, setSelectedService] = React.useState<
    "Web Dev" | "Mobile Dev"
  >("Mobile Dev");
  const [alert, setAlert] = React.useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const form = useRef<HTMLFormElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const emailData = {
      to_email: "phodzo977@gmail.com",
      subject: `Contact Form Submission - ${selectedService}`,
      message: `Name: ${data.name}\nEmail: ${data.email}\nMessage: ${data.message}`,
    };

    try {
      await sendEmail();
      setAlert({
        type: "success",
        message: "Email sent successfully!",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setAlert({
        type: "error",
        message: "Failed to send email. Please try again later.",
      });
    }
  };

  const sendEmail = () => {
    return emailjs.sendForm(
      "service_5lue8l8",
      "template_u3d006c",
      form.current as string | HTMLFormElement,
      {
        publicKey: "s_m5Mhl5r64u04DIh",
      }
    );
  };

  return (
    <Container maxWidth={false} sx={customStyles.container(isMobile, colors)}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        gutterBottom
        sx={{ fontWeight: 600, color: colors.textLight }}
      >
        Connect with DataSpark!
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ color: colors.textMuted, marginBottom: 4 }}
      >
        Let's bring awesomeness to life
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
          <Box display="flex" justifyContent="center" mb={2}>
            <ToggleButtonGroup
              value={selectedService}
              exclusive
              onChange={(e, newValue) => setSelectedService(newValue)}
              aria-label="Service selection"
              sx={{
                backgroundColor: "#1A1D21",
                borderRadius: "20px",
                padding: "4px",
              }}
            >
              <ToggleButton
                value="Web Dev"
                sx={customStyles.toggleButton(colors)}
              >
                Web Dev
              </ToggleButton>

              <ToggleButton
                value="Mobile Dev"
                sx={customStyles.toggleButton(colors)}
              >
                Mobile Dev
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {alert && (
            <Alert
              severity={alert.type}
              onClose={() => setAlert(null)}
              sx={customStyles.alert}
            >
              {alert.message}
            </Alert>
          )}

          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("name", { required: true })}
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name ? "Name is required" : ""}
              InputLabelProps={{ style: { color: colors.textLight } }}
              sx={customStyles.textField(colors)}
            />

            <TextField
              {...register("email", { required: true })}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email ? "Email is required" : ""}
              InputLabelProps={{ style: { color: colors.textLight } }}
              sx={customStyles.textField(colors)}
            />

            <TextField
              {...register("message", { required: true })}
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              error={!!errors.message}
              helperText={errors.message ? "Message is required" : ""}
              InputLabelProps={{ style: { color: colors.textLight } }}
              sx={customStyles.textField(colors)}
            />

            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                type="submit"
                variant="contained"
                sx={customStyles.button}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} md={6} order={isMobile ? 2 : 1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={isMobile ? "center" : "flex-start"}
            textAlign={isMobile ? "center" : "left"}
          >
            <Typography
              variant="h6"
              sx={{ color: colors.textLight, marginBottom: 2 }}
            >
              Contact
            </Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <EmailIcon sx={customStyles.icon(colors)} />
              <Typography variant="body1" sx={{ color: colors.textMuted }}>
                support@dataspark.co.za
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <PhoneIcon sx={customStyles.icon(colors)} />
              <Typography variant="body1" sx={{ color: colors.textMuted }}>
                +27 123 456 7890
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <LocationOnIcon sx={customStyles.icon(colors)} />
              <Typography variant="body1" sx={{ color: colors.textMuted }}>
                Glover and Alethea Street, Centurion, 0163, South Africa
              </Typography>
            </Box>
            <Box
              component="img"
              src={BlueLogo}
              alt="DataSpark Logo"
              sx={customStyles.logo}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
