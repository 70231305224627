import React from 'react';
import './Image.css';

type ImageProps = {
  src: string;
  alt: string;
  className?: string;
};

const Image: React.FC<ImageProps> = ({ src, alt, className }) => (
  <img className={`image ${className}`} src={src} alt={alt} />
);

export default Image;
